:root {
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #73818f;
  --gray-dark: #2f353a;
  --light-blue: #63c2de;
  --primary: #d82020;
  --secondary: #c8ced3;
  --success: #4dbd74;
  --info: #63c2de;
  --warning: #ffc107;
  --danger: #f86c6b;
  --light: #f0f3f5;
  --dark: #2f353a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #23282c;
  text-align: left;
  background-color: #FFF;
}

.login-container {
  background-color: $green-cup-color !important;
}

// button
.btn-primary-cup {
  @include button-variant($green-cup-color, darken($green-cup-color, 7.5%));
}

.btn-primary {
  color: #fff;
  background-color: $green-cup-color;
  border-color: $green-cup-color;
}

.btn-link-gray-cup {
  color: $gray-cup-color;
}

.btn-link {
  font-weight: 400;
  color: $green-cup-color;
  text-decoration: none;
}


.btn {
 
  padding: 0.25rem 0.75rem;
}


// images
.logo {
  background-image: url(./../assets/img/brand/01_LOGO.png);
  height: 70px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
  width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: .25rem;
  width: 100%;
  height: 100%;
  margin-bottom: 0rem;
}

.card-cup {
  background-color: $green-cup-color
}

.card.bg-orange {
  border-color: $orange-cup-color;
}

.bg-orange {
  background-color: $orange-cup-color !important;
}

.card-header-link {
  color: $white-cup-color;
  &:hover {
    color: $white-cup-color;
    text-decoration: none;
  }
}

.card-body {
  flex: 1 1 auto;
  padding: 0.3rem;
}

.link-primary-cup {
  color: $gray-cup-color;
  &:hover {
    color: darken($gray-cup-color, 20.5%);
    text-decoration: none;
  }
}

.table th {
  // padding: 0.75rem;
  // vertical-align: top;
  // border-bottom: 1px dotted #c8ced3;
  border-top: 0px;
}

.table tbody td.dotted {
  // padding: 0.75rem;
  // vertical-align: top;
  // border-bottom: 1px dotted #c8ced3;
  border-top: 0px;
  padding: 10px 20px 10px;
    border-bottom: none;
    background-size: .91rem .6px;
    background-image: linear-gradient(to right, #707070 40%, rgba(255, 255, 255, 0) 0%);
    background-repeat: repeat-x;
    background-position: bottom;
}

.table-sm th, .table-sm td {
  padding: 0.1rem !important;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #c8ced3;
}

.btn-primary-find-cup {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}

.btn-primary:hover {
  color: #fff;
  background-color: $green-hover-cup-color;
  border-color: $orange-cup-color;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $orange-cup-color;
  border-color: $green-hover-cup-color;
}

.btn-warning {
    color: #23282c;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #f30b0b;
  border-color: #f86c6b;
}

.cup-icon {
  font-size: 18px !important;
}

.custom-control-input:checked ~ .custom-control-label.partial::before {
  color: #fff;
  border-color: #869fac;
  background-color: #869fac;
}

.custom-control-input:checked ~ .custom-control-label.full::before {
  color: #fff;
  border-color: #3e515b;
  background-color: #3e515b;
}



.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
   padding: 0.375rem 0.75rem; 
  font-size: 0.875rem; 
   font-weight: 500; 
   line-height: 1.5; 
   color: #000000; 
  background-color: #fff; 
   background-clip: padding-box; 
   border: 1px solid $green-cup-color; 
   border-radius: 0.25rem; 
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a {
  color: $black_cup_color;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: $orange-cup-color;
  text-decoration: underline;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $green-cup-color;
  background-color: #fff;
  border: 1px solid #c8ced3;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: $green-cup-color;
  border-color: $green-cup-color;
}

.modal-primary .modal-header {
  color: #fff;
  background-color: $green-cup-color;
}

.modal-primary .modal-content {
  border-color: $orange-cup-color;
}
.separator {
  border-bottom: 2px solid #707070;
  margin:0px;
  padding: 0px;
}

.form-group.filter {
  margin-bottom: 7px;
}

// label.space{
//   padding-top: 20px
// }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 1;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 1;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 1;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 1;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 1 !important;
}

.deni-react-treeview-container {
  font-family: tahoma,arial;
  font-size: 12px;
  padding: 0px;
  overflow-x: auto;
  overflow-y: auto;
  border: solid 1px;
  border-color: #a5c7e3;
  width: 100% !important;
  height: 350px;
  background-color: #fafafa;
}


* {
  box-sizing: border-box;
}

 

.autocomplete-wrapper {
  width: 350px;
  position: relative;
  display: inline-block;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 12px 15px;
  font-size: 14px;
  width: 100%;
}

.autocomplete-wrapper input:focus {
  border-color: #0F67FF;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0F67FF;
  border-top: none;
}

.autocomplete-wrapper .item  {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #0069ff;
  color: #FAFBFC;
}

.autocomplete-wrapper .item:hover {
  background-color: #0069ff;
  color: #FAFBFC;
}

.nav-tabs .nav-link {
  color: #000000 !important;
}

.modal-dialog {
  max-width: 800px;
margin: 1rem auto;
}

.modal-dialog-p {
  max-width: 500px;
margin: 1rem auto;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: $orange_cup_color;
}

.h6-danger{
  color: #FF0000;
}
