.iconeVerde {
    color: #8f9c4c;
}

.iconeVermelho {
    /* color: #e65119; */
    color: #d25a2e;
}

.iconeAzul {
    color: #3db0f7;
}

.iconeAmarelo {
    color: #fde910;
}

.iconeLaranja {
    color: #DFA131;
}

.icone-mr-5 {
    margin-right: 5px;
}

.icone-mr-10 {
  margin-right: 10px;
}

.icone-ml-5 {
    margin-left: 5px;
}

.icone-ml-10 {
    margin-left: 10px;
}

.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.progressLabel {
    color: black !important;
}


.popover {
    max-width: fit-content !important;
    /* background-color: #eee !important; */
}

/* CSS para estilizar o Multiselect */
.multiSelectContainer {
    display: block !important;
    width: 100% !important;
    color: #000000 !important;
    border: 1px solid #8f9c4c !important;
    border-radius: 0.25rem !important;
}

.searchWrapper {
    border: 0 solid rgb(255 255 255) !important;
    border-radius: 0 !important;
    padding: 3px !important;
    min-height: 22px !important;
    position: relative !important;
}

.chip {
	background: #DFA131 !important;;
    color: #000 !important;;
}

.multiSelectContainer li:hover {
    background: #DFA131 !important;;
    color: #fff !important;;
}

.textoNegrito {
    font-weight: bold;
}

.filter-label {
  width: 100%;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

